import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Power Snatch 4-4-4-4\\@80%1RM`}</p>
    <p>{`then,`}</p>
    <p>{`50-Double Unders`}</p>
    <p>{`25-OHS (95/65)`}</p>
    <p>{`40-Double Unders`}</p>
    <p>{`20-OHS`}</p>
    <p>{`30-Double Unders`}</p>
    <p>{`15-OHS`}</p>
    <p>{`20-Double Unders`}</p>
    <p>{`10-OHS`}</p>
    <p>{`For time.`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`10-Med Ball Cleans (30/20)`}</p>
    <p>{`15-GHD Situps`}</p>
    <p>{`20-Walking Lunges holding Med Ball at Chest`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The sign up sheet for Memorial Day Murph is up next to the front
white board.  We have 15 slots each half hour starting at 10:30am on the
28th so sign up ahead of time!  If you are a visitor and won’t be in
before Murph you can email us and we’ll sign you up.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The CrossFit Regional start this Friday.  Watch the action at
Games.CrossFit.com.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start Sunday, May 20th.  For more
info email Eric at Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      